.input-height .el-input__inner {
  height: 67px;
}
.input-height .n-base-selection-label, .n-base-selection-input {
  height: 67px;
}
.ch-choose-box {
  position: absolute;
  top: 69px;
  right: 0;
  width: 568px;
  height: 250px;
  padding: 10px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  box-sizing: border-box;
}
.ch-choose-box .el-checkbox {
  height: unset;
}
.ch-choose-box .box-header {
  display: flex;
  width: 100%;
}
.ch-choose-box .box-header > div {
  flex: 1;
  height: 32px;
  font-size: 13px;
  color: #606266;
}
.ch-choose-box .box-content {
  width: 100%;
  max-height: 165px;
  overflow-y: auto;
}
.ch-choose-box .box-content .list-item {
  display: flex;
}
.ch-choose-box .box-content .list-item > div {
  flex: 1;
  height: 32px;
  font-size: 13px;
  color: #606266;
}
.ch-choose-box div.item-unit {
  flex: unset !important;
  width: 50px !important;
}
.ch-choose-box div.item-name {
  flex: unset !important;
  width: 170px !important;
  padding-right: 5px;
}
.ch-choose-box div.item-name .el-checkbox {
  display: flex;
}
.ch-choose-box div.item-name .el-checkbox__label {
  display: block;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ch-choose-box div.item-name .inven-end-data {
  display: flex;
  margin-top: 1px;
  padding-left: 23px;
  font-size: 9px;
  line-height: 9px;
}
.ch-choose-box div.item-name .inven-end-data span {
  flex: 1;
}