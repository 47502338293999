.zy[data-v-1c647d9f] {
  word-break: normal;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.el-select-dropdown__item[data-v-1c647d9f] {
  height: 37px;
}
.padding_sty[data-v-1c647d9f] {
  border-bottom: 1px solid #eee;
  margin: 5px;
}
.padding_sty .top_input[data-v-1c647d9f] {
  width: 50px !important;
  border-bottom: none !important;
}
.top_input[data-v-1c647d9f] {
  width: 100px;
  border-bottom: none !important;
  outline: none;
}
.top_item[data-v-1c647d9f] {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.top_item .num_box_item[data-v-1c647d9f] {
  width: 43%;
  flex: 1;
  margin: 5px;
  display: inline-block;
  border-bottom: 1px solid #eee;
}
.top_item .num_box_item label[data-v-1c647d9f] {
  font-size: 12px;
  color: #333;
  font-weight: normal;
  display: inline-block;
  line-height: 24px;
}
.top_item .num_box_item input[data-v-1c647d9f] {
  width: 70% !important;
}
.top_item .top_input[data-v-1c647d9f] {
  flex: 1;
  height: 24px !important;
  display: inline-block;
  background-color: #fff;
  background-image: none;
  box-sizing: border-box;
  color: #606266;
  font-size: inherit;
  line-height: 24px;
  outline: none;
  padding: 0 6px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.top_item .el-select[data-v-1c647d9f] {
  flex: 1;
  display: inline-block;
}
.big_box3[data-v-1c647d9f] {
  width: 1400px;
  border: 1px solid #ddd;
  padding: 20px 60px;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  margin: 20px;
  min-height: 500px;
  position: relative;
}
.big_box3 .left_sh[data-v-1c647d9f] {
  position: absolute;
  left: 60px;
  top: 20px;
}
.big_box3 .left_sh img[data-v-1c647d9f] {
  width: 100px;
  z-index: 99999999999999;
}
.big_box3 .left_sh .status_true[data-v-1c647d9f] {
  padding: 6px 20px;
  border: 2px solid #67c23a;
  font-size: 12px;
  color: #67c23a;
}
.big_box3 .left_sh .status_false[data-v-1c647d9f] {
  padding: 6px 20px;
  border: 2px solid #f56c6c;
  font-size: 12px;
  color: #f56c6c;
}
.big_box3 .moban[data-v-1c647d9f] {
  position: absolute;
  top: 20px;
  right: 50px;
}
.big_box3 .arrow_btn[data-v-1c647d9f] {
  text-align: center;
}
.big_box3 .arrow_btn .two_btn[data-v-1c647d9f] {
  font-size: 20px;
  color: var(--themeColor, #17a2b8);
  line-height: 60px;
  background: #eee;
  cursor: pointer;
}
.big_box3 .arrow_btn .arrow_left[data-v-1c647d9f] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.big_box3 .arrow_btn .arrow_right[data-v-1c647d9f] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.big_box3 .bottom_time[data-v-1c647d9f] {
  float: left;
  width: 40%;
}
.big_box3 .top_input2[data-v-1c647d9f] {
  width: 50%;
}
.big_box3 .top_input2 h6[data-v-1c647d9f] {
  display: inline-block;
  width: 40px;
  font-size: 12px;
  font-weight: normal;
}
.big_box3 .top_input3[data-v-1c647d9f] {
  width: 30%;
}
.big_box3 .top_input3 h6[data-v-1c647d9f] {
  float: left;
  font-size: 12px;
  color: #333;
  font-weight: normal;
  line-height: 26px;
  margin-right: 10px;
}
.big_box3 .top_input3 span[data-v-1c647d9f] {
  font-size: 12px;
  color: #333;
  font-weight: normal;
  line-height: 26px;
  margin-left: 10px;
}
.big_box3 .top_input3 input[data-v-1c647d9f] {
  float: left;
  width: 47%;
  border: none;
  border-bottom: 1px solid #666;
  margin-left: 5px;
  line-height: 22px;
}
.top_title[data-v-1c647d9f] {
  width: 100%;
  margin: 15px auto;
  margin-bottom: 25px;
  position: relative;
}
.top_title h5[data-v-1c647d9f] {
  font-size: 24px;
  color: #333;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.top_title .tags_title[data-v-1c647d9f] {
  font-size: 15px !important;
  color: #666;
  margin-left: 50px;
  position: absolute;
  right: 30%;
  top: 10%;
}
.center_title[data-v-1c647d9f] {
  float: left;
  width: 19%;
  margin: 0 auto;
  text-align: center;
}
.center_title input[data-v-1c647d9f] {
  font-size: 12px;
  color: #333;
  line-height: 36px;
}
.right_num[data-v-1c647d9f] {
  float: right;
  width: 20%;
  text-align: right;
  font-size: 12px;
  color: #333;
}
.content2[data-v-1c647d9f] {
  width: 100%;
  margin: 0 auto;
  border: 3px solid #999;
  margin-top: 10px;
  position: relative;
}
.tops[data-v-1c647d9f] {
  font-size: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tops .add[data-v-1c647d9f] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  left: -28px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
.tops .gsbqw[data-v-1c647d9f] {
  font-size: 0;
  border-top: 1px solid #f3f3f3;
}
.tops .gsbqw div[data-v-1c647d9f] {
  float: left;
  width: 7.6923076923%;
  height: 32px;
  font-size: 12px;
  vertical-align: top;
  border-left: 1px solid #f3f3f3;
}
.tops .gsbqw div[data-v-1c647d9f]:nth-child(3) {
  border-color: #FFA9A9;
}
.tops .gsbqw div[data-v-1c647d9f]:nth-child(6) {
  border-color: #FFD089;
}
.tops .gsbqw div[data-v-1c647d9f]:nth-child(9) {
  border-color: #81ff89;
}
.tops .gsbqw div[data-v-1c647d9f]:nth-child(12) {
  border-color: #9dd7ff;
}
.tops .gsbqw div[data-v-1c647d9f]:nth-child(1) {
  border-left: none;
}
.tops .box_top[data-v-1c647d9f] {
  display: inline-flex;
  flex: 1;
  border-left: 1px solid #666;
  border-bottom: 1px solid #666;
  display: inline-block;
  width: 25%;
  height: 68px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 12px;
  vertical-align: top;
  line-height: 68px;
  box-sizing: border-box;
}
.tops .box_top h6[data-v-1c647d9f] {
  font-weight: 600;
  font-size: 12px;
  color: #333;
}
.tops .box_top > div[data-v-1c647d9f] {
  width: 100%;
  height: 34px;
  line-height: 34px;
  font-weight: 500;
}
.tops .box_top .money_box_top[data-v-1c647d9f] {
  width: 220px;
}
.tops .box_top2[data-v-1c647d9f] {
  display: inline-flex;
  border-left: 1px solid #666;
  border-bottom: 1px solid #666;
  display: inline-block;
  width: 15%;
  height: 68px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 12px;
  vertical-align: top;
  line-height: 68px;
  box-sizing: border-box;
}
.tops .box_top2 h6[data-v-1c647d9f] {
  font-weight: 600;
  font-size: 12px;
  color: #333;
}
.tops .box_top2 > div[data-v-1c647d9f] {
  width: 100%;
  height: 34px;
  line-height: 34px;
  font-weight: 500;
}
.tops .box_top2 .money_box_top[data-v-1c647d9f] {
  width: 220px;
}
.tops .box_top3[data-v-1c647d9f] {
  display: inline-flex;
  border-left: 1px solid #666;
  border-bottom: 1px solid #666;
  display: inline-block;
  width: 30%;
  height: 68px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 12px;
  vertical-align: top;
  line-height: 68px;
  box-sizing: border-box;
}
.tops .box_top3 h6[data-v-1c647d9f] {
  font-weight: 600;
  font-size: 12px;
  color: #333;
}
.tops .box_top3 > div[data-v-1c647d9f] {
  width: 100%;
  height: 34px;
  line-height: 34px;
  font-weight: 500;
}
.tops .box_top3 .money_box_top[data-v-1c647d9f] {
  width: 220px;
}
.tops .box_top4[data-v-1c647d9f] {
  flex: unset;
  width: 25%;
}
.tops .box_top[data-v-1c647d9f]:first-child {
  border-left: none !important;
}
.bottom[data-v-1c647d9f] {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #666;
  min-height: 68px;
  position: relative;
  display: flex;
}
.bottom p[data-v-1c647d9f] {
  font-size: 12px;
  text-align: center;
  line-height: 36px;
  font-weight: 600;
}
.bottom .add[data-v-1c647d9f] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  right: -28px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 25px;
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
.bottom .add .svg-icon[data-v-1c647d9f] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.bottom .close[data-v-1c647d9f] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  right: -32px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
.bottom[data-v-1c647d9f]:last-child {
  border-bottom: none;
}
.common_box[data-v-1c647d9f] {
  width: 25%;
  flex: 1;
  border-left: 1px solid #666;
  position: relative;
}
.common_box2[data-v-1c647d9f] {
  width: 15%;
  border-left: 1px solid #666;
  position: relative;
}
.common_box3[data-v-1c647d9f] {
  width: 30%;
  border-left: 1px solid #666;
  position: relative;
}
.common_box4[data-v-1c647d9f] {
  flex: unset;
  width: 25%;
  font-size: 10px;
}
.common_box4 .top_item[data-v-1c647d9f], .common_box4 .select_box_big[data-v-1c647d9f], .common_box4 .inventory-detail-list[data-v-1c647d9f] {
  height: 100%;
}
.common_box4 .inventory-detail-list[data-v-1c647d9f] {
  overflow-y: auto;
}
.common_box4 .inventory-detail-list-item[data-v-1c647d9f] {
  display: flex;
  padding: 0 10px;
  line-height: 16px;
}
.common_box4 .inventory-detail-list-item span[data-v-1c647d9f] {
  flex: 1;
}
.common_box4 .inventory-detail-list-item span[data-v-1c647d9f]:first-of-type {
  flex: 2;
  padding-right: 5px;
}
.common_box4 .more-box[data-v-1c647d9f] {
  display: flex;
  justify-content: right;
}
.common_box[data-v-1c647d9f]:first-child {
  border-left: 0;
}
.text_big[data-v-1c647d9f] {
  position: relative;
  min-height: 68px;
}
.text_big li[data-v-1c647d9f] {
  float: left;
  width: 7.6923076923%;
  height: 68px;
  line-height: 68px;
  text-align: center;
  font-size: 12px;
  color: #333;
  vertical-align: top;
  border-left: 1px solid #f3f3f3;
  z-index: 8;
  position: relative;
  font-weight: bold;
}
.text_big li[data-v-1c647d9f]:nth-child(1) {
  border-left: none;
}
.text_big input[data-v-1c647d9f] {
  width: 100%;
  height: 100%;
  background: #fff;
  margin: 0 auto;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.infarm_box[data-v-1c647d9f] {
  width: 100%;
  height: 68px;
  font-size: 15px;
  line-height: 22px;
  box-sizing: border-box;
  color: #333;
  border: none;
  margin: 0;
  padding: 5px;
  background: none !important;
}
.td-money[data-v-1c647d9f] {
  width: 100%;
  text-align: center;
  position: relative;
}
.td-money input[data-v-1c647d9f] {
  border: none !important;
  background: none !important;
  width: 100%;
  letter-spacing: 2px;
  text-align: right;
  font-size: 22px;
}
.td-money > div[data-v-1c647d9f] {
  width: 100% !important;
  text-align: right;
  height: 100%;
  font-size: 12px;
}
.td-money span[data-v-1c647d9f] {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 7px;
  top: 0;
}
.bottom_input[data-v-1c647d9f] {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
}
.bottom_input .item_btn[data-v-1c647d9f] {
  width: 46%;
  margin: 0 auto;
  margin-bottom: 15px;
  position: relative;
}
.bottom_input .item_btn h6[data-v-1c647d9f] {
  float: left;
  width: 80px;
  font-size: 15px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
  margin-right: 10px;
  text-align: right;
}
.bottom_input .item_btn p[data-v-1c647d9f] {
  float: left;
  line-height: 36px;
  color: #333;
  font-size: 15px;
  font-weight: normal;
}
.bottom_input .item_btn input[data-v-1c647d9f] {
  float: left;
  width: 47%;
  border: none;
  border-bottom: 1px solid #666;
  margin-left: 5px;
  line-height: 22px;
}
.bottom_input .left_box[data-v-1c647d9f] {
  width: 46%;
}
.bottom_input .right_box[data-v-1c647d9f] {
  width: 46%;
}
.big_boxcode .el-input__inner[data-v-1c647d9f] {
  width: 100%;
  border-radius: 0px !important;
  border: 1px solid #dcdfe6;
}
.big_boxwb .el-input__inner[data-v-1c647d9f] {
  height: 22px !important;
  line-height: 20px !important;
  border-radius: 0px !important;
  border: none !important;
  padding: 0 10px !important;
  font-size: 12px;
}
.big_gsbqw[data-v-1c647d9f] {
  font-size: 0;
  border-top: 1px solid #eee;
  position: relative;
  height: 68px;
}
.big_gsbqw p[data-v-1c647d9f] {
  position: absolute;
  left: 2px;
  top: 27%;
  font-size: 14px;
  font-weight: normal !important;
  text-align: right !important;
  width: 100%;
}
.big_gsbqw > div[data-v-1c647d9f] {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  width: 7.6923076923%;
  height: inherit;
  font-size: 12px;
  vertical-align: top;
  border-left: 1px solid #eee;
}
.big_gsbqw > div[data-v-1c647d9f]:nth-child(3) {
  border-color: #FFA9A9;
}
.big_gsbqw > div[data-v-1c647d9f]:nth-child(6) {
  border-color: #FFD089;
}
.big_gsbqw > div[data-v-1c647d9f]:nth-child(9) {
  border-color: #81ff89;
}
.big_gsbqw > div[data-v-1c647d9f]:nth-child(12) {
  border-color: #9dd7ff;
}
.big_gsbqw > div[data-v-1c647d9f]:nth-child(1) {
  border-left: none;
}
.big_gsbqw > div > span[data-v-1c647d9f] {
  position: unset;
  display: inline;
  width: auto;
  height: auto;
  letter-spacing: normal;
}
.ccyc[data-v-1c647d9f] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.boss[data-v-1c647d9f] {
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
}
.boss .iconfont icon-cuowu[data-v-1c647d9f] {
  position: absolute;
  right: 2px;
  top: 2px;
  color: #f56c6c;
}
.boss .boss-list[data-v-1c647d9f] {
  padding: 5px 10px;
  font-size: 12px;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.subjectWidth[data-v-1c647d9f] {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.table_height[data-v-1c647d9f] {
  overflow-y: overlay;
  overflow-x: hidden;
}
.content[data-v-1c647d9f] {
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
}
.content tr[data-v-1c647d9f] {
  padding: 0;
  margin: 0;
}
.content td[data-v-1c647d9f] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  height: 32px;
  padding: 0 5px;
  color: #333;
  font-size: 13px;
}
.el-select-dropdown__item[data-v-1c647d9f] {
  display: flex;
  align-items: center;
}
.pic[data-v-1c647d9f] {
  position: absolute;
  right: 270px;
  top: -40px;
}
.pic img[data-v-1c647d9f] {
  width: 150px;
}
.record[data-v-1c647d9f] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
}
.record span[data-v-1c647d9f] {
  font-size: 13px;
  font-weight: 500;
  color: var(--themeColor, #17a2b8);
  margin-left: 10px;
}
.record span .iconfont[data-v-1c647d9f] {
  font-size: 13px;
  margin-right: 3px;
}
.record[data-v-1c647d9f]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.image_box[data-v-1c647d9f] {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.image_box img[data-v-1c647d9f] {
  height: 80px;
  border: 1px solid #efefef;
}
.opera_pic[data-v-1c647d9f] {
  position: relative;
  margin-right: 15px;
}
.opera_pic .del_btn[data-v-1c647d9f] {
  position: absolute;
  right: -7px;
  top: -10px;
  color: red;
  cursor: pointer;
}
.bigimg[data-v-1c647d9f] {
  cursor: pointer;
  position: relative;
}
.bigimg input[data-v-1c647d9f] {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
}
.hint_title[data-v-1c647d9f] {
  background-color: #fef6ed;
  font-size: 13px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: #333;
}